import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    id: Number,
    chartId: Number,
    loadPath: String
  };

  addChart() {
    $("#modal").html("").modal("hide");

    this.setChartInput(this.chartIdValue);
    this.toggleChartElements(this.loadPathValue);
  }

  removeChart() {
    this.setChartInput("");
    this.toggleChartElements();
  }

  // private

  setChartInput(value) {
    const $el = this.miPackEntityElement().querySelectorAll("[name='mi_pack_entity[chart_id]']")[0];
    $el.value = value;
    $el.dispatchEvent(new Event("change"));
  }

  toggleChartElements(loadPath) {
    const $el = this.miPackEntityElement();
    $el.getElementsByClassName("add-chart")[0].classList.toggle("hidden");
    $el.getElementsByClassName("remove-chart")[0].classList.toggle("hidden");

    const $widget = $el.getElementsByClassName("widget-chart")[0];
    if (loadPath) {
      // Load via the existing mechanism i.e. reports.coffee
      $widget.setAttribute("data-initial-path", loadPath);
      $widget.innerHTML = "<div class='spinner'></div>";
      $widget.dispatchEvent(new CustomEvent("report-container:loaded", { bubbles: true }));
    } else {
      $widget.innerHTML = "";
    }
  }

  miPackEntityElement() {
    return document.getElementById(`edit_mi_pack_entity_${this.idValue}`);
  }
}
