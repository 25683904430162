import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    formId: String,
    inputName: String,
    input: String,
    selected: Boolean,
    mutuallyExclusive: Boolean
  };

  // TODO: upgrade from jquery-ujs to rails_ujs to utilse the `form` as a target
  // https://www.betterstimulus.com/rails-ujs/submit-form.html
  static targets = ["icon"];

  static classes = ["selected", "unselected"];

  select(event) {
    event.preventDefault();

    if (this.element.classList.contains("disabled")) {
      return;
    }

    this.toggleClass();

    this.selectedValue = !this.selectedValue;
    this.updateFormInput(`${this.inputNameValue}[]`, this.inputValue, this.selectedValue);

    if (this.mutuallyExclusiveValue) {
      this.exclusiveMode();
    }
  }

  apply(event) {
    event.preventDefault();

    Rails.fire(this.form()[0], "submit");
  }

  reset(event) {
    event.preventDefault();

    Rails.fire($(`#${this.formIdValue}-reset`)[0], "submit");
  }

  // private

  exclusiveMode() {
    const selecting = this.selectedValue;
    this.element.parentElement.querySelectorAll("[data-filter-mutually-exclusive-value='true'][data-filter-selected-value='false']").forEach(function(element) {
      if (selecting) {
        element.classList.add("disabled");
      } else {
        element.classList.remove("disabled");
      }
    });
  }

  toggleClass() {
    this.iconTarget.classList.toggle(this.unselectedClass);
    this.iconTarget.classList.toggle(this.selectedClass);
  }

  updateFormInput(name, value, selected) {
    if (selected) {
      this.form().append(this.formInput(name, value));
    } else {
      this.form().find(`[name="${name}"][data-id="${value}"]`).remove();
    }
  }

  formInput(name, value) {
    return `<input type="hidden" name="${name}" value="${value}" data-id="${value}">`;
  }

  form() {
    return $(`#${this.formIdValue}`);
  }
}
