import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.scrollTo(this.todayOffset());
  }

  resetToTop() {
    window.scrollTo(0, 0);
  }

  // private

  scrollTo(offset) {
    $("html, body").animate({
      scrollTop: offset
    }, "fast");
  }

  todayOffset() {
    const today = $(".today");
    if ($(".alert-error").length) {
      return 0;
    } else if (today.length) {
      return today.offset().top - this.navigationHeight();
    }
  }

  navigationHeight() {
    return $(".navbar-fixed-top").height() + $(".calendar-container .navigation").height() + 50; // + 50px margin
  }

  pageinated() {
    // If the user has scrolled beyond the height of a calendar, it must have pageinated.
    return $(window).height() + $(document).scrollTop() > $(".calendar-container .calendar").height();
  }
}
