import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const uuid = $("body").data("uuid");
    this.hideAuthorOnOwnPacks(uuid);
    this.hideStateOnSharedByOthers(uuid);
  }

  hideAuthorOnOwnPacks(uuid) {
    $(`.mi-pack[data-author-uuid="${uuid}"] .author`).addClass("hidden");
  }

  hideStateOnSharedByOthers(uuid) {
    $(`.mi-pack:not([data-author-uuid="${uuid}"]) [data-state]`).addClass("hidden");
  }
}
