import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import "bootstrap-switch/dist/js/bootstrap-switch.js";

export default class extends Controller {
  static targets = ["checkbox"];

  connect() {
    // Check if the target checkbox has already been initalised
    if (!$(this.checkboxTarget).parent().hasClass("bootstrap-switch-container")) {
      $(this.checkboxTarget).bootstrapSwitch();
      $(this.checkboxTarget).on("switchChange.bootstrapSwitch", (event) => {
        Rails.fire($(this.element)[0], "submit");
      });
    };
  }
}
