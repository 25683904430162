import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["table", "panel", "dropdownMenu"];
  static values = {
    breakpoint: { type: Number, default: 768 } // $screen-sm - https://getbootstrap.com/docs/3.4/customize/#media-queries-breakpoints
  };

  connect() {
    this.setWidth();
  }

  // Called when resizing the screen
  setWidth() {
    this.setResponsiveElements();
  }

  // Called when tables are dynamically added to the screen
  tableTargetConnected(element) {
    this.setElementWidth(element, this.width(element));
  }

  // Called when panels are dynamically added to the screen
  panelTargetConnected(element) {
    this.setElementWidth(element, this.width(element));
  }

  // Called when dropdowns are dynamically added to the screen
  dropdownMenuTargetConnected(element) {
    this.setElementWidth(element, this.width(element));
  }

  // private

  setResponsiveElements() {
    this.tableTargets.forEach(table => {
      this.setElementWidth(table, this.width(table));
    });
    this.panelTargets.forEach(panel => {
      this.setElementWidth(panel, this.width(panel));
    });
    this.dropdownMenuTargets.forEach(dropdownMenu => {
      this.setElementWidth(dropdownMenu, this.width(dropdownMenu));
    });
  }

  width(element) {
    const windowWidth = $(window).width();
    const breakpointAttr = element?.getAttribute("data-breakpoint-value");

    // Convert to number and fallback to default
    const breakpoint = breakpointAttr ? Number(breakpointAttr) : this.breakpointValue;
    return (windowWidth < breakpoint) ? `${windowWidth - 30}px` : "auto";
  }

  setElementWidth(element, width) {
    element.style.width = width;
  }
}
