// This controller is an enhanced implementation of:
// https://github.com/gorails-screencasts/autosave-draft-records/blob/master/app/javascript/controllers/autosave_controller.js
import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["form", "status"];

  connect() {
    this.timeout = null;
    this.duration = this.data.get("duration") || 1000;
  }

  save() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setStatus("submitting");
      Rails.fire($(this.formTarget)[0], "submit");
    }, this.duration);
  }

  changed() {
    this.setStatus("unsaved");
  }

  success() {
    this.setStatus("saved");
  }

  error() {
    this.setStatus("error");
  }

  setStatus(state) {
    this.statusTarget.innerHTML = $(this.statusTarget).data(`${state}-icon`);

    // Update the icon tooltip
    $("[role='tooltip']").fadeOut("normal", () => { $(this).remove(); });
    const title = $(this.statusTarget).data(`${state}-text`);
    $(this.statusTarget).find("[rel='tooltip']").attr("title", title);
  }
}
