import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["requiredInput", "submitButton"];

  input() {
    this.ensureSubmitButtonState();
  }

  // private

  ensureSubmitButtonState() {
    this.submitButtonTarget.disabled = !this.areAllInputsFilled();
  }

  areAllInputsFilled() {
    return this.requiredInputTargets.every(input => input.value.trim() !== "");
  }
}
