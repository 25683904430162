import { Controller } from "stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = {
    action: { type: String, default: "sort" },
    url: String
  };

  static targets = ["column"];

  connect() {
    this.initializeSortable();
  }

  // private

  initializeSortable() {
    this.columnTargets.forEach(column => {
      if (!column.classList.contains("ui-sortable")) {
        $(column).sortable({
          revert: "true",
          containment: "document",
          helper: "clone",
          cursor: "move",
          placeholder: "ui-state-highlight",
          forcePlaceholderSize: true,
          opacity: 0.8,
          connectWith: ".widgets",
          items: ".panel"
        });
        $(column).on("sortupdate", () => {
          const ids = $(column).sortable("toArray", {
            attribute: "data-widget-preference-id"
          }).join(",");

          if (ids.length > 0) {
            this.submitForm(column.getAttribute("data-column"), ids);
          }
        });
        $(column).on("sortout", () => {
          // Prevent the tooltip from getting stuck on the screen when moving the widgets SSNEXT-8612
          $(".tooltip.in").remove();
        });
      }
    });
  }

  async submitForm(columnPosition, ids) {
    const params = { widget_preference_batch: { action: this.actionValue, ids, column_position: columnPosition } };
    await post(this.urlValue, { body: JSON.stringify(params) });
  }
}
