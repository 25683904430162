import consumer from "./consumer";

const parameterize = function(status) {
  return status.replace("...", "").toLowerCase();
};

$(document).on("opening:load", function() {
  $(".placement .integration-status").each(function() {
    const $el = $(this);

    // no need to subscribe to updates after the integration op has completed
    if ($el.hasClass("label-success") || $el.hasClass("label-failure")) {
      return;
    }

    consumer.subscriptions.create({
      channel: "PlacementChannel",
      id: $el.closest(".placement").data("id")
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(info) {
        // Called when there's incoming data on the websocket for this channel
        let msg;
        $el.text(info.response_status);
        if ((msg = info.message)) {
          $el.attr("title", msg.replace(/\n/g, "<br>")).tooltip();
        }
        $el.removeClass().addClass("integration-status label label-" + (parameterize(info.response_status)));
      }
    });
  });
});
