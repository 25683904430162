var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"clearfix\">\n  <span class=\"ref-number pull-left\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":37},"end":{"line":2,"column":43}}}) : helper)))
    + "</span>\n  <time datetime=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"starts_on") || (depth0 != null ? lookupProperty(depth0,"starts_on") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"starts_on","hash":{},"data":data,"loc":{"start":{"line":3,"column":18},"end":{"line":3,"column":31}}}) : helper)))
    + "\" class=\"pull-left\">"
    + alias4((lookupProperty(helpers,"moment")||(depth0 && lookupProperty(depth0,"moment"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"starts_on") : depth0),{"name":"moment","hash":{"format":"ddd D MMM YYYY"},"data":data,"loc":{"start":{"line":3,"column":51},"end":{"line":3,"column":95}}}))
    + "</time>\n  <small class=\"text-muted pull-right\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"pattern") || (depth0 != null ? lookupProperty(depth0,"pattern") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pattern","hash":{},"data":data,"loc":{"start":{"line":4,"column":39},"end":{"line":4,"column":50}}}) : helper)))
    + "</small>\n</div>\n";
},"useData":true});