import consumer from "./consumer";

$(document).on("page:show", function() {
  $(".authenticated.timesheets .timesheet.success").each(function() {
    consumer.subscriptions.create({
      channel: "TimesheetChannel",
      id: $(this).data("timesheet-id")
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel

        $(".fa-spinner").remove();

        let url;
        if ((url = data.redirect_to)) {
          $(".continue.hidden").attr("href", url).removeClass("hidden");
          window.location.href = url;
        } else {
          $(".information").html(data.information.replace(/\n/g, "<br>")).addClass("text-danger");
          if ((url = data.back_to)) {
            $(".return.hidden").attr("href", url).removeClass("hidden");
          }
        }
      }
    });
  });
});
