import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "acceptAllButton",
    "rejectAllButton",
    "offerRequestedCheckbox",
    "offerRequestedButton",
    "shortlistedCheckbox",
    "shortlistedButton",
    "rejectedCheckbox",
    "rejectedButton"
  ];

  connect() {
    this.ensureButtonStates();
  }

  toggleAll(event) {
    this.toggle(event, true);
  }

  toggleOne(event) {
    this.toggle(event, false);
  }

  // private

  toggle(event, actionAll) {
    const button = this.locateButton(event);
    const checkbox = button.querySelector("[type='checkbox']");
    const toState = button.getAttribute("data-active") !== "true";
    this.toggleState(button, checkbox, toState);

    if (actionAll) {
      const targetType = button.getAttribute("data-toggle-type");
      this.updateAll(targetType, toState);
    } else {
      this.ensureButtonStates();
    }
  }

  ensureButtonStates() {
    // The select all/none buttons
    this.ensureAllButtonState(this.rejectedCheckboxTargets, this.rejectAllButtonTarget);
    this.ensureAllButtonState([...this.offerRequestedCheckboxTargets, ...this.shortlistedCheckboxTargets], this.acceptAllButtonTarget);
  }

  ensureAllButtonState(checkboxes, button) {
    const allChecked = checkboxes.every(checkbox => checkbox.checked);
    this.toggleClassAndAttribute(button, allChecked && checkboxes.length > 0);
  }

  updateAll(targetType, selected) {
    const acceptSelected = targetType === "accepted" && selected;
    const acceptSelectionOnly = targetType === "accepted" && !selected;
    const rejectSelected = targetType === "rejected" && selected;
    const rejectSelectionOnly = targetType === "rejected" && !selected;

    if (!rejectSelectionOnly) {
      this.toggleCheckboxesAndButtons(
        this.offerRequestedCheckboxTargets,
        this.offerRequestedButtonTargets,
        acceptSelected
      );
      this.toggleCheckboxesAndButtons(
        this.shortlistedCheckboxTargets,
        this.shortlistedButtonTargets,
        acceptSelected
      );
    }

    if (!acceptSelectionOnly) {
      this.toggleCheckboxesAndButtons(
        this.rejectedCheckboxTargets,
        this.rejectedButtonTargets,
        rejectSelected
      );
    }
  }

  toggleCheckboxesAndButtons(checkboxes, buttons, isSelected) {
    checkboxes.forEach(checkbox => {
      checkbox.checked = isSelected;
    });
    buttons.forEach(button => {
      this.toggleClassAndAttribute(button, isSelected);
    });
  }

  toggleState(button, checkbox, active) {
    button.setAttribute("data-active", active);
    checkbox.checked = active;

    if (active) {
      // Deactivate other buttons/checkboxes in the button group
      const buttonGroup = button.closest(".btn-group");
      this.deactivateOtherButtonsAndCheckbox(buttonGroup, button);
    }
  }

  deactivateOtherButtonsAndCheckbox(buttonGroup, activeButton) {
    const buttons = buttonGroup.querySelectorAll(".btn");
    buttons.forEach(button => {
      if (button !== activeButton) {
        this.toggleClassAndAttribute(button, false);
        const checkbox = button.querySelector("[type='checkbox']");
        checkbox.checked = false;
      }
    });
  }

  toggleClassAndAttribute(element, condition) {
    if (condition) {
      element.classList.add("active");
    } else {
      element.classList.remove("active");
    }
    element.setAttribute("data-active", condition);
  }

  locateButton(event) {
    let allButton = event.target;
    // If the 'fa' icon was clicked within the button ...
    if (!allButton.classList.contains("btn")) {
      // locate the actual button
      allButton = event.target.closest(".btn");
    }
    return allButton;
  }
}
