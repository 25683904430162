import { Controller } from "stimulus";

export default class extends Controller {
  // TODO: define a default when upgrading to Stimulus 3.0
  static values = { multiRole: String };
  static targets = ["role", "roles", "form"];

  connect() {
    const checked = this.findCheckedRole();
    if (checked) {
      this.disableRoles(checked);
    }
    this.updateRole();
  }

  // Triggered when a role checkbox is clicked
  selectRole(event) {
    if (event.target.checked) {
      this.disableRoles(event.target);
    } else {
      this.enableRoles();
    }
    this.updateRole();
  }

  // private

  // Disable the checkboxes that are not allowed to be selected,
  // based upon the current selection
  disableRoles(element) {
    const allowed = element.getAttribute("data-allowed");

    this.rolesTargets.forEach(role => {
      role.disabled = !allowed.includes(role.value);
    });
  }

  // Enable the checkboxes if none are checked
  enableRoles() {
    const unchecked = this.rolesTargets.every(role => {
      return !role.checked;
    });

    if (unchecked) {
      this.rolesTargets.forEach(role => {
        role.disabled = false;
      });
    }
  }

  // Find the first checked role
  findCheckedRole() {
    return this.rolesTargets.find(role => {
      return role.checked;
    });
  }

  // Update the role on the form input ready for submission.
  updateRole() {
    const checked = this.rolesTargets.filter(role => role.checked);

    if (checked.length > 1) { // multi-role selected aka 'agency' & 'bank'
      this.roleTarget.value = this.multiRoleValue;
    } else if (checked.length === 1) { // single role selected
      this.roleTarget.value = this.findCheckedRole().value;
    } else { // no role selected
      this.roleTarget.value = "";
    }
  }
}
