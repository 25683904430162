import consumer from "./consumer";

$(document).on("page:show openings:load", function() {
  const postingIds = $(".unsubscribed.opening[data-posting-id]").map((i, el) => $(el).data("posting-id")).get();

  // Use the distinct set of posting ids
  new Set(postingIds).forEach((postingId) => {
    consumer.subscriptions.create({ channel: "PostingChannel", id: postingId }, {
      connected() {
        // Called when the subscription is ready for use on the server
        $(`.unsubscribed.opening[data-posting-id="${postingId}"]`)
          .removeClass("unsubscribed")
          .addClass("subscribed");
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        $(`.subscribed.opening[data-posting-id="${postingId}"]`)
          .removeClass("subscribed")
          .addClass("unsubscribed");
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel

        // Update the status on each opening listed.
        /* eslint-disable indent */
        $(`.opening[data-posting-id="${postingId}"] .status .label`)
          .removeClass(data.remove_class)
          .addClass(data.status)
          .text(data.status_text);
        /* eslint-enable indent */
      }
    });
  });
});
