import Rails from "@rails/ujs";

const InfiniteScroll = require("infinite-scroll");

$(document).on("page:show remote:load content:loaded openings:load", () => {
  $(".infinite-pages").each((_i, el) => {
    const $el = $(el); // jQuery wrapper
    const selector = ".pagination-link a[rel='next']";

    if ($el.find(selector).length > 0) {
      const infScroll = new InfiniteScroll(el, {
        path: selector,
        elementScroll: $el.data("scroll-context"),
        scrollThreshold: $el.data("scroll-threshold") || 400, // px - specifying in case they change the default
        history: false,
        loadOnScroll: false // Instead, handle the scrollThreshold event below...
      });

      /**
       * The catch with the infinite-scroll package is that it's not designed for
       * use with javascript responses, so I've disabled its load behaviour and
       * written this custom handler for the `scrollThreshold` event:
       */
      infScroll.on("scrollThreshold", () => {
        const $linkContainer = $el.find(".pagination-link");
        const url = $linkContainer.find("a[rel='next']").attr("href");
        if (!url) return;

        Rails.ajax({
          type: "GET",
          url,
          dataType: "script",
          beforeSend: () => $linkContainer.html("<i class=\"fa fa-spinner fa-spin fa-fw fa-2x\">"),
          error: () => $linkContainer.text("There was an error loading the next page").addClass("text-danger")
        });
      });
    }
  });
});
