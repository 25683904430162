import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import Turbolinks from "turbolinks";

export default class extends Controller {
  static targets = ["output", "icon"];
  static classes = ["expanded", "collapsed"];
  static values = {
    url: String,
    trigger: String, // TODO: define a default { type: String, default: "on-load" } stimulus v3 required
    event: String
  };

  connect() {
    if (this.triggerValue === "scroll") {
      const controller = this;
      /* eslint-disable no-unused-vars */
      /* eslint-disable no-undef */
      const waypoint = new Waypoint({
        element: this.element,
        handler: function() {
          controller.loadContent();
        },
        // Start loading when the content is 20% lower than the bottom of the screen
        offset: "120%"
      });
      /* eslint-enable no-undef */
      /* eslint-enable no-unused-vars */
    } else if (this.triggerValue === "") { // Ideally the `triggerValue` would default to "on-load"
      this.loadContent();
    }
  }

  toggleIcon(event) {
    event.preventDefault();

    this.iconTarget.classList.toggle(this.expandedClass);
    this.iconTarget.classList.toggle(this.collapsedClass);
  }

  async loadContent() {
    const source = this;
    if (!source.data.get("loaded")) {
      const response = await get(this.urlValue, {
        headers: { "X-Content-Loader": true },
        responseKind: "html"
      });
      if (response.statusCode === 401) {
        this.displayModal();
      } else if (response.ok) { // if the file exists on S3
        const target = (this.hasOutputTarget ? this.outputTarget : this.element);
        // update the target with the content in the response
        const html = await response.html;
        target.innerHTML = html;

        // trigger events
        target.dispatchEvent(new CustomEvent("content:loaded", { bubbles: true }));
        if (this.hasEventValue) {
          target.dispatchEvent(new CustomEvent(this.eventValue, { bubbles: true }));
        }
        source.data.set("loaded", true);
      }
    }
  }

  displayModal() {
    // Hide any non 'session-expired' modals
    $(".modal:not(.session-expired)").modal("hide");

    // Display the 'session-expired' modal if it's not already displayed
    if ($(".modal.session-expired").length === 0) {
      /* eslint-disable no-undef */
      dataConfirmModal.confirm({
        title: "Session expired",
        text: "Your session has expired. Please sign-in again.",
        commit: "Continue",
        cancelClass: "invisible",
        modalClass: "session-expired",
        onConfirm: function() {
          return Turbolinks.visit(Routes.new_user_session_path());
        }
      });
      /* eslint-enable no-undef */
    }
  }
}
