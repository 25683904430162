import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dropdown", "menu", "overflowIndicator"];

  connect() {
    // Resize the dropdown _after_ it has been rendered by bootstrap.
    $(this.dropdownTarget).on("shown.bs.dropdown", (event) => {
      this.resize();
    });

    // Allow menu's to stay open when options are selected.
    if (this.menuTarget.classList.contains("stay-open")) {
      $(this.menuTarget).on("click", (event) => {
        event.stopPropagation();
      });
    }
  }

  resize() {
    this.setMaxHeight();
    this.displayScrollIndicator();
  }

  // private

  setMaxHeight() {
    // TODO: The margin value could be derived at runtime from the
    //       Navbar height plus the margin required under the dropdown.
    //       Consideration for top nav + a margin below the open dropdown
    //       and the freshdesk help button SSNEXT-9358
    const margin = 210;
    this.menuTarget.style.maxHeight = `${window.innerHeight - margin}px`;
  }

  displayScrollIndicator() {
    const scrollBottom = this.menuTarget.scrollTop + this.menuTarget.clientHeight;

    if (scrollBottom < this.menuTarget.scrollHeight) {
      this.overflowIndicatorTarget.classList.remove("hidden");
      this.overflowIndicatorTarget.style.top = `${scrollBottom - this.overflowIndicatorTarget.clientHeight}px`;
    } else { // scrolled to the bottom of the menu
      this.overflowIndicatorTarget.classList.add("hidden");
    }
  }
}
