/* eslint-disable */
// Halt the form submission while the token is being fetched and then replay the submission
const fetchRecaptchaToken = function(ev) {
  $form = $(this)
  const $input = $form.find("input.g-recaptcha-response");
  if ($input.length === 0) return; // nothing to do if recaptcha is disabled

  const action = /\[(.+)\]$/.exec($input.attr("name"))[1];
  grecaptcha.execute($input.data("sitekey"), {action: action})
            .then(function(token) {
    $input.val(token);

    // Now restart the ajax form submission without this preprocessing
    $form.addClass("submitting"); // removed by re-render or ajax:complete
    $form.trigger("submit.rails");
  });

  // Prevent form submission from continuing until the token has been fetched
  return false; // TODO: change to ev.preventDefault() once we move to rails-ujs
}

// The token should be generated immediately before it is submitted to the backend for verification
$(document).on("ajax:before", "form.form-recaptcha[data-remote='true']:not(.submitting)", fetchRecaptchaToken);
$(document).on("ajax:complete", "form.submitting", function() {
  $(this).removeClass("submitting");
});
/* eslint-enable */
