import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["worksiteDeviatesCheckbox", "deviationReasonRow", "startTimeInput", "endTimeInput", "breakDurationInput"];
  static values = {
    workedHoursDeviationReasonRequired: Boolean,
    rosteredStartTime: String,
    rosteredEndTime: String,
    rosteredBreakDuration: String
  };

  connect() {
    this.toggleDeviationReason();
  }

  toggleDeviationReason() {
    this.deviationReasonRowTarget.classList.toggle("hidden", !this.displayDeviationReason());
  }

  // private

  displayDeviationReason() {
    return (this.hasWorksiteDeviatesCheckboxTarget && this.worksiteDeviatesCheckboxTarget.checked) ||
    (this.workedHoursDeviationReasonRequiredValue && (
      (this.hasStartTimeInputTarget && this.startTimeInputTarget.value !== this.rosteredStartTimeValue) ||
      (this.hasEndTimeInputTarget && this.endTimeInputTarget.value !== this.rosteredEndTimeValue) ||
      (this.hasBreakDurationInputTarget && this.breakDurationInputTarget.value !== this.rosteredBreakDurationValue)
    ));
  }
}
