import consumer from "./consumer";
import Rails from "@rails/ujs";

$(document).on("page:show invoice_run:created", function() {
  $(".invoice_runs .invoice_run .loading").each(function() {
    const $invoiceRun = $(this).closest(".invoice_run");

    consumer.subscriptions.create({
      channel: "InvoiceRunChannel",
      id: $invoiceRun.data("id")
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel

        if (data.path) {
          // Trigger a request to load information on screen
          Rails.ajax({ type: "GET", url: data.path, dataType: "script" });
        }
      }
    });
  });
});
