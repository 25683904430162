var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"clearfix\" data-client-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"rostered_shift") : depth0)) != null ? lookupProperty(stack1,"client_id") : stack1), depth0))
    + "\">\n  <span class=\"ref-number pull-left\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"rostered_shift") : depth0)) != null ? lookupProperty(stack1,"opening_id") : stack1), depth0))
    + "</span>\n  <time datetime=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"rostered_shift") : depth0)) != null ? lookupProperty(stack1,"starts_on") : stack1), depth0))
    + "\" class=\"pull-left\">"
    + alias2((lookupProperty(helpers,"moment")||(depth0 && lookupProperty(depth0,"moment"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"rostered_shift") : depth0)) != null ? lookupProperty(stack1,"starts_on") : stack1),{"name":"moment","hash":{"format":"ddd D MMM YYYY"},"data":data,"loc":{"start":{"line":3,"column":66},"end":{"line":3,"column":125}}}))
    + "</time>\n  <small class=\"text-muted pull-right\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"rostered_shift") : depth0)) != null ? lookupProperty(stack1,"pattern") : stack1), depth0))
    + "</small>\n</div>\n";
},"useData":true});