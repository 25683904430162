import { Controller } from "stimulus";

export default class extends Controller {
  static classes = ["hidden"];
  static targets = ["loader"];
  static values = {
    dynamicAttributes: Array,
    requiredAttributes: Object
  };

  // Called when the loader `select` element is rendered aka initalise.
  loaderTargetConnected(element) {
    this.hideDynamicAttributeInputs();
    this.showRequiredAttributeInputs(element.value);
  }

  // Called when an option is selected via the loader `select` element.
  loaderSelected(event) {
    this.hideDynamicAttributeInputs();
    this.showRequiredAttributeInputs(event.target.value);
  }

  // private

  hideDynamicAttributeInputs() {
    this.updateClassForInputs(this.dynamicAttributesValue, /* hide */ true);
  }

  showRequiredAttributeInputs(loaderId) {
    const requiredAttributes = this.requiredAttributesValue[loaderId];

    requiredAttributes && this.updateClassForInputs(requiredAttributes, /* hide */ false);
  }

  updateClassForInputs(attributes, hide) {
    attributes.forEach((attribute) => {
      document.querySelectorAll(`.field.upload_${attribute}`).forEach((element) => {
        hide ? element.classList.add(this.hiddenClass) : element.classList.remove(this.hiddenClass);
      });
    });
  }
}
