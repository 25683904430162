import consumer from "./consumer";
import Rails from "@rails/ujs";

$(document).on("page:show remote:load", () => {
  $(".asset[data-scan-result='unknown']").each(function() {
    const $asset = $(this);

    consumer.subscriptions.create({
      channel: "AssetChannel",
      checksum: $asset.data("checksum")
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        // Trigger a request to (re-)load the assets for an attachable displayed on screen
        Rails.ajax({ type: "GET", url: $asset.data("path"), dataType: "script" });

        // Download the requested asset
        if (data.asset_path) {
          window.location.replace(data.asset_path);
        }
      }
    });
  });
});
