import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];
  static classes = ["disabled"];

  async generate(event) {
    if (this.buttonTarget.disabled) {
      return;
    }

    event.preventDefault();
    this.disableButton();

    try {
      // Get the MIME type from the data-mime attribute
      const mimeType = this.buttonTarget.dataset.mime || "application/octet-stream";
      // Use the URL path from the data attribute
      const urlPath = this.buttonTarget.dataset.urlPath;

      const response = await fetch(urlPath, {
        headers: {
          Accept: mimeType,
          "X-Requested-With": "XMLHttpRequest"
        }
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Modern web browsers implement strict security measures to prevent malicious
      // scripts from automatically downloading files without user interaction.
      // Use the existing button for download and set filename from data attribute
      this.buttonTarget.href = url;
      this.buttonTarget.download = this.buttonTarget.dataset.filename;
      this.buttonTarget.click();

      // Clean up the object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // TODO: Handle error (e.g., show an error message to the user)
    } finally {
      this.enableButton();
    }
  }

  disableButton() {
    this.buttonTarget.disabled = true;
    this.buttonTarget.classList.add(this.disabledClass);
  }

  enableButton() {
    this.buttonTarget.disabled = false;
    this.buttonTarget.classList.remove(this.disabledClass);
  }
}
