import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["startTimeInput", "endTimeInput", "predefinedTimesBtn"];

  connect() {
    this.selectPredefinedTimes();
  }

  populateTimes(event) {
    // TODO: append :prevent to the data-action call once upgraded to stimulus V3 https://stimulus.hotwired.dev/reference/actions#options
    event.preventDefault();

    this.unselectPredefinedTimesBtns();

    const btn = event.currentTarget;
    this.startTimeInputTarget.value = btn.getAttribute("data-start-time");
    this.endTimeInputTarget.value = btn.getAttribute("data-end-time");

    this.selectPredefinedTimesBtn(btn);
  }

  selectPredefinedTimes(event) {
    if (this.hasPredefinedTimesBtnTarget) {
      this.unselectPredefinedTimesBtns();

      this.predefinedTimesBtnTargets.forEach(btn => {
        this.selectPredefinedTimesBtn(btn);
      });
    }
  }

  selectPredefinedTimesBtn(btn) {
    if (this.startTimeInputTarget.value === btn.getAttribute("data-start-time") &&
        this.endTimeInputTarget.value === btn.getAttribute("data-end-time")) {
      btn.classList.add("active");
    }
  }

  unselectPredefinedTimesBtns() {
    this.predefinedTimesBtnTargets.forEach(btn => {
      btn.classList.remove("active");
    });
  }
}
