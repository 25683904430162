/* eslint-disable import/first */
import accounting from "accounting";
accounting.settings.currency.symbol = "£";
window.accounting = accounting;

import BigNumber from "bignumber.js/bignumber.js";
window.BigNumber = BigNumber;

import "bootstrap-sass";

import "bootstrap-switch/dist/js/bootstrap-switch.js";
import "bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css";

import Chartkick from "chartkick";
import Chart from "chart.js/auto";
Chartkick.use(Chart);

// data-confirm-modal needs access to @rails/ujs via "window.Rails"
import Rails from "@rails/ujs";
window.Rails = Rails;
require("data-confirm-modal"); // # Using an 'import' doesn't work.

import "datatables.net-bs";
import "datatables.net-bs/css/dataTables.bootstrap.css";

import "./fontawesome.scss";

import moment from "moment";
window.moment = moment;
import "moment/dist/locale/en-gb";
import "moment-recur";

import Handlebars from "handlebars/runtime.js";
// Proxy to moment() instance - implementation based on http://git.io/tAv3Zg.
Handlebars.registerHelper("moment", function(context, block) {
  let date = moment(context);
  for (const key in block.hash) {
    const value = block.hash[key];
    if (date[key]) {
      date = date[key](value);
    }
  }
  return date;
});

// https://github.com/railsware/js-routes#setup-manually
import * as Routes from "../routes.js";
// TODO: Remove global exposure and import where required.
window.Routes = Routes;

import "jquery-ui/ui/widgets/slider";
import "jquery-ui/ui/effect";
import "jquery-ui/ui/effects/effect-highlight";
import "jquery-ui/ui/effects/effect-fade";
import "jquery-ui/ui/effects/effect-slide";
import "jquery-ui/ui/widgets/autocomplete";
import "jquery-ui/ui/widgets/sortable";
import "jquery-ui/ui/i18n/datepicker-en-GB";
import "jquery-ui/ui/widget";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/themes/base/all.css"; // jQuery-UI styling

import "jquery-ui-timepicker-addon/src/jquery-ui-timepicker-addon.js";
import "jquery-ui-timepicker-addon/src/jquery-ui-timepicker-addon.css";

import "infinite-scroll";

import "select2-v3/select2.js";
import "select2-v3/select2.css"; // styling
import "select2-v3/select2-bootstrap.css";

import "signature_pad";

import _ from "underscore";
window._ = _;

import "waypoints/lib/jquery.waypoints.js";

import "world-flags-sprite/stylesheets/flags32.scss";
/* eslint-enable import/first */
