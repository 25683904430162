import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = { publicationId: Number, supplierId: Number };
  static targets = ["button", "output"];

  publish() {
    this.buttonTarget.classList.add("hidden");
    this.submitForm();
    this.outputTarget.classList.remove("hidden");
  }

  submitForm() {
    const form = $("#new-supplier-publication-form");
    form.find("[name='supplier_publication[publication_id]").val(this.publicationIdValue);
    form.find("[name='supplier_publication[supplier_id]").val(this.supplierIdValue);
    Rails.fire(form[0], "submit");
  }
}
