import consumer from "./consumer";

function subscribe(el) {
  const $upload = $(el);
  const finalStatuses = ["failed", "reverted"];
  if (finalStatuses.includes($upload.data("status"))) {
    return;
  }

  consumer.subscriptions.create({
    channel: "UploadChannel",
    id: $upload.data("id")
  }, {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel

      $upload.find(".status").attr("class", "status upload-" + data.status).text(data.status_text);

      switch (data.status) {
        case "loaded":
          $upload.find(".record-count").removeClass("hidden").text(data.records);
          $upload.find(".btn-revert").removeClass("hidden");
          break;
        case "failed":
          $upload.find(".modal-link").removeClass("hidden").text(data.failures);
          break;
        case "reverting":
          $upload.find(".btn-revert").addClass("hidden");
          break;
        case "revert_failed":
          /* eslint-disable no-undef */
          dataConfirmModal.confirm({
            title: data.status_text,
            text: data.message,
            commit: data.confirm_button,
            cancelClass: "invisible"
          });
          /* eslint-enable no-undef */
          break;
      }
    }
  });
}

$(document).on("page:show", () => {
  $(".upload").each((_idx, el) => subscribe(el));
});

$(document).on("upload:created", (ev) => subscribe(ev.target));
