import { Controller } from "stimulus";

export default class extends Controller {
  // select2 doesn't appear to play well stimulus targets
  // We'll manually look for the required inputs and submit button

  change(event) {
    this.ensureSubmitButtonState();
  }

  // private

  ensureSubmitButtonState() {
    if (this.submitButton()) {
      this.submitButton().disabled = !this.areAllInputsFilled();
    }
  }

  areAllInputsFilled() {
    // find all inputs in the form that contain the 'required' class.
    const requiredInputs = this.form().querySelectorAll("input.required");
    return Array.from(requiredInputs).every(input => input.value.trim() !== "");
  }

  form() {
    return this.element;
  }

  submitButton() {
    // The submit button exists outside of the form, e.g. in the modal footer.
    const formId = this.form().getAttribute("id");
    return document.querySelector(`button[form="${formId}"]`);
  }
}
