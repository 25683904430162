import consumer from "./consumer";

$(document).on("page:show", () => {
  $("#posting_budget_container").each((idx, el) => {
    let $container = $(el);
    consumer.subscriptions.create({
      channel: "BudgetChannel",
      posting_id: $container.data("posting-id")
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(msg) {
        // Called when there's incoming data on the websocket for this channel
        $container = $("#posting_budget_container"); // only load if this is still on page
        $container.load($container.data("initial-path"));
        $("#posting_approval_chains_container").each((idx, el) => {
          const $acContainer = $(el);
          $acContainer.load($acContainer.data("initial-path"));
        });

        if (msg.submissible) {
          $("form.send_for_approval_form .send_for_approval.btn").removeClass("hidden");
        }
      }
    });
  });
});
