import consumer from "./consumer";
import Rails from "@rails/ujs";

$(document).on("report:created", function(ev, reportId) {
  consumer.subscriptions.create({
    channel: "ReportChannel",
    id: reportId
  }, {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel

      // Trigger a request to load the new report details on screen
      Rails.ajax({ type: "GET", url: data.path, dataType: "script" });
    }
  });
});
