var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"credential-type\" data-credential-type-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":54},"end":{"line":1,"column":60}}}) : helper)))
    + "\">\n  <div>\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":13}}}) : helper)))
    + "\n    <small class=\"label label-info label-xs\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"human_predicate") || (depth0 != null ? lookupProperty(depth0,"human_predicate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"human_predicate","hash":{},"data":data,"loc":{"start":{"line":4,"column":45},"end":{"line":4,"column":64}}}) : helper)))
    + "</small>\n  </div>\n  <small class=\"text-muted\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"job_positions_to_s") || (depth0 != null ? lookupProperty(depth0,"job_positions_to_s") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"job_positions_to_s","hash":{},"data":data,"loc":{"start":{"line":6,"column":28},"end":{"line":6,"column":50}}}) : helper)))
    + "</small>\n</div>\n";
},"useData":true});