import { Controller } from "stimulus";
import { get } from "@rails/request.js";
import Turbolinks from "turbolinks";

export default class extends Controller {
  static targets = ["input"];
  static values = {
    url: String,
    notFound: String
  };

  // URL is not supported in op_mob 12.1, op_mini all, IE Mobile 10, IE 10, bb 7
  search() {
    const url = new URL(this.urlValue);
    url.search = new URLSearchParams({ id: this.inputTarget.value }).toString();

    this.lookup(url);
  }

  // private

  async lookup(url) {
    const response = await get(url, { responseType: "json" });

    /* eslint-disable no-undef */
    if (response.ok) {
      const json = await response.json;
      if (json.length > 0) { // result found
        Turbolinks.visit(json[0].url);
      } else {
        window.dataConfirmModal.confirm({ // TODO: i18n
          title: "Not found",
          text: this.notFoundValue,
          commit: "Continue",
          cancelClass: "invisible"
        });
      }
    }
    /* eslint-enable no-undef */
  }
}
