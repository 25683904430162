import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["sourceCheckbox", "checkbox"];

  toggle() {
    const isChecked = this.sourceCheckboxTarget.checked;
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked;
    });
  }
}
